import {OrganisationSubscription} from '@/models/OrganisationSubscription';
import {OrganisationSubscription as OrganisationSubscriptionResource} from "@/resources/forge";

const store = {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
  	setList (state, list) {
      state.list = list;
    },
  },
  actions: {
  	async load({commit}) {
      const res = await OrganisationSubscriptionResource.get();
      commit('setList',
        res.body.organisation_subscriptions.map(os => new OrganisationSubscription(os)));
      return res;
    },
    async checkSubscription({commit}, params) {
      const res = await OrganisationSubscriptionResource.checkSubscription({}, params)
        .then((res) => {
          if (res.body.organisation)
            commit("organisations/updateCurrentOrg", res.body.organisation, {root: true});
          return res.body
        })
    },
    async updateCardDetails({commit}, params) {
      const res = await OrganisationSubscriptionResource.updateCardDetails({}, params)
        .then((res) => {
          if (res.body.organisation)
            commit("organisations/updateCurrentOrg", res.body.organisation, {root: true});
          return res.body
        })
    },
    async saveSubscription({commit}, params) {
      const res = await OrganisationSubscriptionResource.updateSubscription({}, params)
        .then((res) => {
          if (res.body.organisation)
    	      commit("organisations/updateCurrentOrg", res.body.organisation, {root: true});
    	    return res.body
        })
        .catch(e => {
    	    return e.body
        })
      return res;
    }
  }
}

  export default store;